import React, {useEffect, useRef} from 'react'
import notfoundStyles from "@/public/styles/notfound.module.scss";


function Custom404(props) {
    return (
        <>
            <div className={notfoundStyles.container}>
                <div className={notfoundStyles.error}>
                    Страница не найдена
                </div>
            </div>
        </>
    )
}

export default Custom404